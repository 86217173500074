import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL+'api' }),
  endpoints: (builder) => ({
    getMenu: builder.query({
      query: () => `cms/get_menu`,
    }),
    getCmsSetting: builder.query({
      query: () => `cms/get_cms_setting`,
    }),
    saveMenu: builder.mutation({
      query: (data) => ({
        url: `cms/save_menu`,
        method: 'POST',
        body: { menu:data },
      })
    }),
    saveCmsSetting: builder.mutation({
      query: (formdata) => ({
        url: `cms/save_cms_setting`,
        method: 'POST',
        formData: true,
        body: formdata,
      }),
    }),
  })
});
export const { useGetMenuQuery, useSaveMenuMutation, useGetCmsSettingQuery, useSaveCmsSettingMutation } = api;
