import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { AgreePolicy, ChooseFile, ConfirmationText, DescriptionLabel, EmailAddress, FirstName, Password, State, Submit } from '../../../Utils/Constants';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { Btn } from '../../../AbstractElements';
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { useGetCmsSettingQuery, useSaveCmsSettingMutation } from '../../../ReduxToolkit/Services/ApiService';
import { toast } from 'react-toastify';

export default function CmsForm() {
    const [preview, setPreview] = useState<any>();
    const { register, handleSubmit, formState: { errors }, setValue, setError,clearErrors } = useForm();
    const [formDataObj, setFormDataObj] = useState<any>()
    const [save, { isLoading }] = useSaveCmsSettingMutation()
    const response = useGetCmsSettingQuery('ff')
    if (response.status == 'fulfilled') {
        let formdata = response.data.data;
        // setFormDataObj({ ...formdata })

        Object.keys(formdata).forEach(key => {
            if (key !== 'logo')
                setValue(key, formdata[key]);
        });
    }



    const saveForm = async(data: any) => {

        data.logo = data['logo'][0]
        // console.log(data)
        if (data.show_google_reviews)
            data.show_google_reviews = 'Yes'
        else
            data.show_google_reviews = 'No'
        const formData = new FormData();

        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                formData.append(key, data[key]);
            }
        }

        try {
            const response = await save(formData).unwrap();
            // Handle successful mutation
            toast.success('Setting saved  successfully');
          } catch (error) {
            // Handle error
            toast.error('Failed to save setting ');
          }
       

    }
    const registerRs = (fieldName: string, options = {}) => {
        const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }

    useEffect(() => {
        console.log('errors', errors.company_email)
    }, [errors])

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        const maxSize = 1 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
            setError('logo', {
                type: 'manual',
                message: 'File size should be less than 1MB'
            });
            setValue('logo',null)
            return;
        }
        else if (!['image/png', 'image/gif', 'image/jpeg'].includes(file.type)) {
            setError('logo', {
                type: 'manual',
                message: 'Only Image file allowed'
            });
            setValue('logo',null)
            return;
        }
        clearErrors('logo');
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const renderImage = (field: any) => {
        if (preview) {
            return <img src={preview} width='80' height='80' />
        }
        else {
            if (response['data'] && response['data']['data']['logo'].length > 0)
                return <img src={`${process.env.REACT_APP_SERVER_URL}storage/logo/${response['data']['data']['logo']}`} width='80' height='80' />
            else
                return " "

        }
    }

    return (
        <div className="page-body">
            <Container>
                <Row>
                    <Col sm={12}>
                        <Card className=" title-line">
                            <CommonCardHeader title={'Edit Frontend Setting'} />
                            <CardBody className="custom-input">
                                <Form onSubmit={handleSubmit(saveForm)}>
                                    <Row className="g-3">
                                        <Col xs={4}>
                                            <Label htmlFor="first-name">Company Name</Label>
                                            <Input id="first-name" type="text" placeholder="Company Name"
                                                {...registerRs("company_name")}

                                            />

                                            {errors.company_name && <p className="form-error">Field is required</p>}

                                        </Col>

                                        <Col xs={4}>
                                            <Label htmlFor="exampleFormControlInput1">Company Email Address</Label>
                                            <Input id="exampleFormControlInput1" className={errors.company_email ? 'is-invalid' : ''}
                                                placeholder="support@wemail.com"  {...registerRs("company_email", { pattern: /^\S+@\S+$/i })}

                                            />

                                            {errors.company_email && <p className="form-error">Email is invalid</p>}
                                        </Col>
                                        <Col xs={4}>
                                            <Label htmlFor="exampleFormControlInput1">Company Phone </Label>
                                            <Input id="exampleFormControlInput1" type="number" placeholder="+912233444"
                                                className={errors.company_contact_no ? 'is-invalid' : ''}
                                                {...registerRs("company_contact_no", {
                                                    pattern: /^[1-9\-\(\)\, ]+$/
                                                })}

                                            />

                                            {errors.company_contact_no && <p className="form-error">Phone Number is invalid </p>}
                                        </Col>
                                        <Col xs={12}>
                                            <Label htmlFor="exampleFormControlTextarea1" >Address</Label>
                                            <Input type="textarea" id="exampleFormControlTextarea1" rows="3"  {...registerRs("company_address")} />
                                        </Col>
                                        <Col xs={12}>
                                            <Label htmlFor="exampleFormControlTextarea12" >Google Map Code</Label>
                                            <Input type="textarea" id="exampleFormControlTextarea12" rows="3" {...registerRs("google_map_code")} />
                                        </Col>

                                        <Col xs={12}>
                                            <Label htmlFor="formFile">Logo</Label>
                                            <Input id="formFile" type="file"  {...registerRs("logo")} onChange={handleFileChange} />
                                            {errors.logo && <p className="form-error" dangerouslySetInnerHTML={{ __html: `<span>${errors.logo.message}</span>` }} />}
                                            {renderImage('logo')}

                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="exampleFormControlInput1">Facebook</Label>
                                            <Input id="exampleFormControlInput1"  {...registerRs("facebook")} />
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="exampleFormControlInput1">Twitter</Label>
                                            <Input id="exampleFormControlInput1"   {...registerRs("twitter")} />
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="exampleFormControlInput1">Instagram</Label>
                                            <Input id="exampleFormControlInput1"   {...registerRs("instagram")} />
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="exampleFormControlInput1">Whatsapp</Label>
                                            <Input id="exampleFormControlInput1" type="number" {...registerRs("whatsapp", {
                                                pattern: /^[0-9\-\(\)\, ]+$/
                                            })} />
                                        </Col>




                                        <Col xs={12}>
                                            <FormGroup check switch>
                                                <Input type="checkbox" id="flexSwitchCheckDefault"  {...registerRs("show_google_reviews")} />
                                                <Label htmlFor="flexSwitchCheckDefault" check>Show Google Reviews </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <Btn color="primary" size={'sm'} className="btn-square">
                                                {isLoading ? 'Loading' : 'Submit'}</Btn>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>


    )
}
