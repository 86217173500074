import { SidebarMenuType } from "../../Types/Layout.type";

export const menuList: SidebarMenuType[] = [
    {
        title: 'General',
        menu: [
            {
                title: 'Dashboard',
                icon: "home",

                url: `/pages/dashboard`,
                type: "link",
            },
            {
                title: 'CRM',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Sales Pipeline',
                        url: `/pages/sales_pipeline`,
                        type: "link",
                    },
                    {
                        title: 'Lead List',
                        url: `/pages/lead_list`,
                        type: "link",
                    },
                ],
            },
            {
                title: 'ERP',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Sales Management',
                        url: `/pages/sales_management`,
                        type: "link",
                    },
                    {
                        title: 'Purchase Management',
                        url: `/pages/purchase_management`,
                        type: "link",
                    },
                    {
                        title: 'Customers',
                        url: `/pages/customers`,
                        type: "link",
                    },
                ],
            },
            {
                title: 'Templates',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'SMS',
                        url: `/pages/sms_templates`,
                        type: "link",
                    },
                    {
                        title: 'Whatsapp',
                        url: `/pages/whatsapp_template`,
                        type: "link",
                    },
                    {
                        title: 'Email',
                        url: `/pages/email_template`,
                        type: "link",
                    },
                    {
                        title: 'Push Notification',
                        url: `/pages/email_template`,
                        type: "link",
                    },
                ],
            },
            {
                title: 'Api Integration',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Whatsapp Api',
                        url: `/pages/whatsapp_api`,
                        type: "link",
                    },
                    {
                        title: 'Email',
                        url: `/pages/email_api`,
                        type: "link",
                    },
                    {
                        title: 'SMS',
                        url: `/pages/sms_api`,
                        type: "link",
                    },
                    {
                        title: 'Payment Gateway',
                        url: `/pages/payment_gateway`,
                        type: "link",
                    },
                    {
                        title: 'Shipping Gateway',
                        url: `/pages/shipping_gateway`,
                        type: "link",
                    },
                ],
            },
            {
                title: 'Front CMS',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Menu Builder',
                        url: `/pages/sample_page`,
                        type: "link",
                    },

                    {
                        title: 'General Setting',
                        url: `/pages/cms_edit`,
                        type: "link",
                    },


                ]
            },
            {
                title: 'Ecommerce ',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Product',
                        url: `/pages/whatsapp_api`,
                        type: "link",

                    },
                    {
                        title: 'Category',
                        url: `/pages/whatsapp_api`,
                        type: "link",

                    },
                    {
                        title: 'Order Management',
                        url: `/pages/whatsapp_api`,
                        type: "link",

                    },

                ],
            },
            {
                title: "System Setting",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "General Seting",
                        target: '_blank',
                        url: '#',
                        type: "link"
                    },
                    {
                        title: "Role/Permission",
                        target: '_blank',
                        url: '#',
                        type: "link"
                    }
                ]
            }

        ]
    }
];