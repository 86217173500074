
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader'

import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useGetMenuQuery, useSaveMenuMutation } from '../../../ReduxToolkit/Services/ApiService'

export default function SamplePageContainer() {

    const [change, setChange] = useState<any>(Math.random() * 1000)
    const firstTime = useRef<any>(false)
    const [saveMenu, { isLoading }] = useSaveMenuMutation()
    const { data: resp } = useGetMenuQuery('ff')
    const [selected, setSelected] = useState<any>(null)
    const [startingLeft, setStartingLeft] = useState<any>(0)
    const [activeTab, setActiveTab] = useState<any>('1')

    const addNameRef = useRef<any>(null)
    const addLinkRef = useRef<any>(null)
    const editNameRef = useRef<any>(null)
    const editLinkRef = useRef<any>(null)
    const [menu, setMenu] = useState<any>([])
    const [finalMenu, setFinalMenu] = useState<any>([])

    useEffect(() => {
        if (resp) {
            let c = JSON.parse(resp.menu);
            let m = JSON.parse(c.menu);
            setMenu([...m])
            setFinalMenu([...m])
        }
    }, [resp]);
    function getClosestSiblingAbove(target: any) {
        let p = target.previousElementSibling;
        let sibling = p && p.classList.contains('dropTarget') ? p : null
        while (sibling) {
            if (sibling.nodeType === 1) { // Check if it's an element node
                return sibling;
            }
            let p = sibling.previousElementSibling;
            sibling = p && p.classList.contains('dropTarget') ? p : null
        }
        return null; // No sibling found
    }
    useEffect(() => {
        if (menu && menu.length > 0) {

            const droparea = document.getElementById("dropArea");
            const AlldropTargets = droparea!.getElementsByClassName('dropTarget');

            let first = Array.from(AlldropTargets)[0]
            setStartingLeft(first.getBoundingClientRect().left)
            const dragStartListener = (event: any) => {

                let target = event.target as HTMLElement | null;
                if (target) {
                    offsetX = event.clientX - target.getBoundingClientRect().left;

                    draggingElement = target!.parentNode!.parentNode;
                }
            }
            const dragEndListener = (event: any) => {

                setChange(Math.random() * 1000)
            }
            const dragoverListener = (event: any) => {
                event.preventDefault()
                let el = event.target as HTMLElement

                //  let element=document.elementFromPoint(event.clientX,event.clientY)
                let target = el.closest(".dropTarget");

                if (target) {
                    if (target.id != draggingElement.id) {
                        let rect = target.getBoundingClientRect();
                        const offsetY = event.clientY - rect.top;

                        if (offsetY < rect.height / 2) {
                            target.parentNode!.insertBefore(draggingElement, target);
                        } else {
                            target.parentNode!.insertBefore(draggingElement, target.nextElementSibling);
                        }

                    }
                    else {
                        let sibling = getClosestSiblingAbove(target)
                        if (sibling) {

                            const dragElementBack = target.querySelector('.dragElementBack') as HTMLElement;
                            const dragElement = target.querySelector('.dragElement') as HTMLElement;
                            let sib_left = sibling.querySelector('.dragElementBack').getBoundingClientRect().left;
                            let diff = sib_left - startingLeft;
                            const x = event.clientX - offsetX - sib_left;

                            if (x >= 0) {
                                if (x > 50) {
                                    dragElementBack!.style.left = `${diff + 80}px`;
                                    dragElement!.style.left = `0px`;
                                    dragElementBack.setAttribute('data-parent', sibling.id)

                                }
                                else {

                                    dragElement!.style.left = `0px`;
                                    dragElementBack!.style.left = `${diff}px`;
                                    dragElementBack.setAttribute('data-parent', sibling.querySelector('.dragElementBack').getAttribute('data-parent'))

                                }
                            }
                            else {
                                dragElementBack.removeAttribute('data-parent')
                                dragElement!.style.left = `0px`;
                                dragElementBack!.style.left = `0px`;


                            }
                        }
                    }

                }


            }

            let draggingElement: any = null;
            let offsetX: any;
            droparea!.addEventListener("dragstart", dragStartListener);
            droparea!.addEventListener("dragover", dragoverListener);
            droparea!.addEventListener("dragend", dragEndListener);



            return (() => {
                droparea!.removeEventListener("dragstart", dragStartListener);
                droparea!.removeEventListener("dragover", dragoverListener);
                droparea!.removeEventListener("dragend", dragEndListener);
            })
        }


    }, [startingLeft, menu])
    useEffect(() => {

        if (menu && menu.length > 0) {


            if (!firstTime.current) {

                firstTime.current = true
                let all_remove = Array.from(document.getElementsByClassName('remove'))
                all_remove.length > 0 && all_remove.forEach((el: any) => {

                    unwrapParent(el)
                })
                let all_top = Array.from(document.getElementsByClassName('top'))
                all_top.length > 0 && all_top.forEach((el: any) => {

                    unwrapParent(el)
                    el.remove()
                })
            }


            let all = Array.from(document.getElementsByClassName('dragElementBack'))
            let ar: any;
            let drop_area_left = document.getElementById('dropArea')!.getBoundingClientRect().left
            all.forEach((el: any) => {
                let parent_id = el.getAttribute('data-parent')
                if (parent_id) {

                    let parent_element = document.getElementById(parent_id)
                    if(parent_element){
                    let grand_parent = parent_element!.querySelector('.dragElementBack')!.getAttribute('data-parent');
                    let parent_rect = parent_element?.getBoundingClientRect()
                    let parent_left = parent_rect!.left

                    el.style.left = `${drop_area_left - parent_left + (grand_parent ? 2 * 80 : 80)}px`
                    }



                }


            })
        }


    }, [menu, firstTime])
    useEffect(() => {

        let all = Array.from(document.getElementsByClassName('dragElementBack'))
        if (all.length > 0) {
            let ar: any;
            let new_ar = all.reduce((accumulator: any, current: any) => {
                let parent = current.getAttribute('data-parent')
                if (parent) {
                    let parent_name = parent.split('_')[1]
                    let child_name = current.parentNode.id.split('_')[1]/***because dragElementBack iske parentnode mein id laga hai not in current */

                    if (accumulator[parent_name])
                        accumulator[parent_name].push(child_name);
                    else {
                        accumulator[parent_name] = []
                        accumulator[parent_name].push(child_name);
                    }
                }
                else {
                    let name = current.parentNode.id.split('_')[1]

                    if (name)
                        accumulator[name] = []
                }
                return accumulator
            }, [])
            let x: any = [];
            Object.entries(new_ar).forEach(([key, value]: any) => {
                let children: any = [];
                if (value.length > 0) {
                    value.forEach((el: any) => {
                        let children1: any = [];
                        if (new_ar[el]) {
                            if (new_ar[el].length > 0) {
                                new_ar[el].forEach((el: any) => {
                                    let existing_obj = getObjectByName(menu, el)
                                    let obj = { name: el, label: el, 'link': existing_obj?.link, children: [] }
                                    children1.push(obj)
                                })
                            }
                            delete new_ar[el];

                        }
                        let existing_obj = getObjectByName(menu, el)
                        let obj = { name: el, label: el, 'link': existing_obj?.link, children: children1 }
                        children.push(obj)
                    })
                }
                let existing_obj = getObjectByName(menu, key)
                let obj = { name: key, label: key, 'link': existing_obj?.link, children }
                x.push(obj)
            });

            setFinalMenu(x)
        }
    }, [change])

    const addMenu = (e: any) => {
        let obj = { name: addNameRef.current.value, label: addNameRef.current.value, link: addLinkRef.current.value, children: [] }

        setMenu([...menu, obj])
        setFinalMenu([...menu, obj])
    }
    function updateNestedObject(array: any, key: any, valueToUpdate: any, newValue: any) {
        array.forEach((item: any) => {
            if (item[key] === valueToUpdate) {
                item[key] = newValue;
            }
            if (item.children) {
                updateNestedObject(item.children, key, valueToUpdate, newValue);
            }
        });
    }
    const updateMenu = (e: any) => {

        let obj = getObjectByName(menu, selected)

        updateNestedObject(menu, 'name', selected, editNameRef.current.value)
        updateNestedObject(menu, 'label', selected, editNameRef.current.value)
        updateNestedObject(menu, 'link', obj['link'], editLinkRef.current.value)
        setMenu([...menu])
        setFinalMenu([...menu])
        setSelected(null)
    }
    const deleteMenu = (name: any) => {
        document.getElementById('id_' + name)?.remove()
        setChange(Math.random() * 1000)
    }
    function unwrapParent(parentNode: any) {

        const grandparentNode = parentNode.parentNode;
        if (grandparentNode) {

            while (parentNode.firstChild) {
                grandparentNode.insertBefore(parentNode.firstChild, parentNode);
            }

            if (grandparentNode.contains(parentNode))
                parentNode.remove();
        }
    }
    const saveForm = async(e: any) => {

        try {
            const response = await  saveMenu(JSON.stringify(finalMenu)).unwrap();
            // Handle successful mutation
            toast.success('Menu saved successfully');
          } catch (error) {
            // Handle error
            toast.error('Failed to save menu');
          }
       
    }
    useEffect(() => {
        if (selected) {
            setActiveTab('2')
            let obj = getObjectByName(menu, selected)

            editNameRef.current!.value = obj['label'];
            editLinkRef.current!.value = obj['link'];
        }
        else
            setActiveTab('1')
    }, [selected])
    function getObjectByName(array: any, name: any) {
        for (let item of array) {
            if (item.name === name) {
                return item;
            }
            if (item.children) {
                const foundInChildren = getObjectByName(item.children, name) as any;
                if (foundInChildren) {
                    return foundInChildren;
                }
            }
        }
        return null; // Object not found
    }
    const generateHtml = (menu: any, parent_id?: any) => {

        return menu.map((el: any, index: any) => {
            return (<div key={index} className={parent_id ? "remove" : 'top'}>
                <div className="dropTarget" id={`id_${el['name']}`} draggable="true">
                    <div className="dragElementBack" data-parent={parent_id} onClick={() => setSelected(el['name'])}>
                        <div className="dragElement" draggable="true" dangerouslySetInnerHTML={{ __html: `${el['label']}` }} />
                    </div>
                    <span style={{ color: 'red', position: 'absolute', top: '3px', right: '10px', cursor: 'pointer' }} onClick={(e: any) => deleteMenu(el['name'])}>
                        <i className="fa fa-trash" ></i>
                    </span>

                </div>
                {
                    el.children && el.children.length > 0 && generateHtml(el.children, `id_${el.name}`)
                }</div>)
        })

    }

    return (
        <Container fluid>
            <Row><Col sm={12}>
            <Row>
                            <Col sm={4}>
                                <Card  >

                                    <CardBody>
                                        <div>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={activeTab == '1' ? "active" : ""}
                                                        onClick={() => setActiveTab('1')}
                                                    >
                                                        Add Menu
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink disabled={selected == null}
                                                        className={activeTab == '2' ? "active" : ""}
                                                        onClick={() => setActiveTab('2')}
                                                    >
                                                        Edit Menu
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1" style={{ paddingTop: '20px' }}>
                                                    <Row>
                                                        <Col sm="12">

                                                            <Form >
                                                                <FormGroup>
                                                                    <Label for="exampleEmail">
                                                                        Menu Name
                                                                    </Label>
                                                                    <Input
                                                                        innerRef={addNameRef}
                                                                        id="exampleEmail"
                                                                        name="name"
                                                                        placeholder=""

                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label for="examplePassword">
                                                                        Menu Link
                                                                    </Label>
                                                                    <Input
                                                                        innerRef={addLinkRef}
                                                                        id="examplePassword"
                                                                        name="link"
                                                                        placeholder=""

                                                                    />
                                                                </FormGroup></Form>

                                                            <Button variant={'primary'} size={'sm'} className="btn-square pull-right" onClick={addMenu}>
                                                                Add Menu
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="2" style={{ paddingTop: '20px' }}>

                                                    <Form >
                                                        <FormGroup>
                                                            <Label for="exampleEmail">
                                                                Menu Name
                                                            </Label>
                                                            <Input
                                                                innerRef={editNameRef}
                                                                id="exampleEmail"
                                                                name="name"
                                                                placeholder=""

                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="examplePassword">
                                                                Menu Link
                                                            </Label>
                                                            <Input
                                                                innerRef={editLinkRef}
                                                                id="examplePassword"
                                                                name="link"
                                                                placeholder=""

                                                            />
                                                        </FormGroup></Form>

                                                    <Button variant={'primary'} className="btn-square pull-right" size={'sm'} onClick={updateMenu}>
                                                        Update Menu
                                                    </Button>
                                                </TabPane>
                                            </TabContent>
                                        </div>



                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={8}>
                                <Card className='title-line'>
                                    <CardBody>

                                        <div id="dropArea">
                                            {
                                                menu !== undefined && menu.length > 0 && generateHtml(menu, null)
                                            }


                                        </div>
                                        <Button size={'sm'} color={'primary'} className="btn-square pull-right mt-2" onClick={saveForm}>
                                            {isLoading ? 'Wait..' : 'Save Menu'}</Button>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
            </Col>
            </Row>


        </Container>
    )
}
