import React, { FormEvent, useState } from 'react'
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Btn, H2, H6, Image, P } from '../AbstractElements';
import { dynamicImage } from '../Utils';

import { CreateAccount, DontHaveAccount, EmailAddress, ForgotPassword, Password, RememberPassword, SignIn, SignInAccount, SignInWith } from '../Utils/Constants';
import SocialLink from './SocialLink';
import axios from 'axios';
export default function SignInForm() {
    const navigate = useNavigate();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [email, setEmail] = useState("test123@gmail.com");
    const [password, setPassword] = useState("Test@123");
    const toggle = () => setPasswordVisible(!isPasswordVisible);
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        axios.post('api/auth/login', {
            email,
            password
        })
        .then(response => {
            // Handle success
            let resp= response.data;
            localStorage.setItem("user", JSON.stringify(resp['user']));
            localStorage.setItem("token",resp['authorisation']['token']);
          
            toast.success("Login Success...!");
       navigate(`/pages/sample_page`);
           
        })
        .catch(error => {
            // Handle error
            console.error('Error:', error);
        });
       
    };
    return (
        <div>
            <div>
                <Link className='logo' to={ `/pages/sample_page`}>
                    <Image className="img-fluid for-light" src={dynamicImage(`logo/logo.png`)} alt="looginpage" />
                    <Image className="img-fluid for-dark" src={dynamicImage(`logo/logo_dark.png`)} alt="looginpage"></Image>
                </Link>
            </div>
            {/* <h1>App URL: {import.meta.env.VITE_SECRET}</h1> */}
            <div className="login-main">
                <Form className="theme-form" onSubmit={handleSubmit}>
                    <H2>{SignInAccount}</H2>
                    <P>{"Enter your email & password to login"}</P>
                    <FormGroup>
                        <Col><Label>{EmailAddress}</Label></Col>
                        <Input type="email" defaultValue={email} onChange={(event) => setEmail(event.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Col><Label>{Password}</Label></Col>
                        <div className="form-input position-relative">
                            <Input type={isPasswordVisible ? 'text' : 'password'} defaultValue={password} onChange={(event) => setPassword(event.target.value)} name="login[password]" />
                            <div className='show-hide' onClick={toggle}>
                                <span className={!isPasswordVisible ? 'show' : ''}></span>
                            </div>
                        </div>
                    </FormGroup>
                    <div className="mb-0 form-group">
                        <div className="checkbox p-0">
                            <Input id="checkbox1" type="checkbox" />
                            <Label className="text-muted" htmlFor="checkbox1">{RememberPassword}</Label>
                        </div>
                        <Link className="link" to={ `/auth/forget-password`}>{ForgotPassword}</Link>
                        <div className="text-end mt-3">
                            <Btn color='primary' className="w-100" block>{SignIn}</Btn>
                        </div>
                    </div>
                    <H6 className="text-muted mt-4 or">{SignInWith}</H6>
                    <SocialLink />
                    <P className="mt-4 mb-0 text-center">{DontHaveAccount}
                        <Link className="ms-2" to={ `/auth/sign-up`}>{CreateAccount}</Link>
                    </P>
                </Form>
            </div>
        </div >
    )
}
