import LeadListPage from "../Components/Pages/LeadListPage";
import DashboardPage from "../Components/Pages/DashboardPage";
import SamplePage from "../Pages/Pages/SamplePage";
import SalesPipelinePage from "../Components/Pages/SalesPipelinePage";
import CmsForm from "../Components/Pages/CMS";
export const routes = [
    { path: '/pages/sample_page', element: <SamplePage /> },
    { path: '/pages/dashboard', element: <DashboardPage /> },
    { path: '/pages/sales_pipeline', element: <SalesPipelinePage /> },
    { path: '/pages/cms_edit', element: <CmsForm /> },
    { path: '/pages/lead_list', element: <LeadListPage /> }
]
