
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'



export default function SalesPipelinePage() {
  return (
    <div className='page-body'>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card className='title-line'>
              <CardHeader title={'Saels Pipeline'} />
              <CardBody>
                Sales Pipeline
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container></div>
  )
}
