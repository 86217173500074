import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import LayoutRoutes from './LayoutRoutes'
import SignIn from '../Auth';
import PrivateRoutes from './PrivateRoutes';

export default function Routers() {
    const login = JSON.parse(localStorage.getItem("user")!) || false;

    return (
        <BrowserRouter basename={'/'}>
            <Routes>
                {login ? (
                    <>
                        <Route
                            path={'/'}
                            element={<Navigate to={`/pages/sample_page`} />}
                        />
                    </>
                ) : (
                   ""
               
                )}
                <Route path={"/"} element={<PrivateRoutes />}>
                    <Route path={`/*`} element={<LayoutRoutes />} />
                </Route>
                <Route path={`/login`} element={<SignIn />} />
            </Routes>
        </BrowserRouter>
    )
}
